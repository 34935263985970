import React from "react";

class SearchPagination extends React.Component {
    constructor(props) {
        super(props)
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
    }

    previousPage() {
        if (this.props.getNum() === 0) {
            return;
        }
        var currNum = this.props.getNum();
        this.props.changeNum(currNum-1);
    }

    nextPage() {
        if (this.props.getNum() === Math.ceil(this.props.total/this.props.size)-1) {
            return;
        }
        var currNum = this.props.getNum();
        this.props.changeNum(currNum+1);
    }

    gotoPage(num) {
        if (num < 0 || num > Math.ceil(this.props.total/this.props.size)-1) {
            return;
        }
        this.props.changeNum(num);
    }

    render() {
        var maxPages = Math.ceil(this.props.total/this.props.size);
        var prevDisabled = this.props.getNum() === 0;
        var nextDisabled = this.props.getNum() === (maxPages-1);
        var pageBar;
        var current = this.props.getNum();
        if (maxPages <= 6) {
            var buttons = [...Array(maxPages).keys()]
            let gotoPage = this.gotoPage;
            var inner = buttons.map(function(val, i) {
                    if (current === val) {
                        return <li><a class="pagination-link is-current" aria-current="page" aria-label={"Goto page " + (i+1)}>{i+1}</a></li>;
                    } else {
                        return <li><a onClick={()=>gotoPage(i)} class="pagination-link" aria-label={"Goto page " + (i+1)}>{i+1}</a></li>;
                    }
                }
            );
            pageBar = 
            <ul class="pagination-list">
                {inner}
            </ul>;
        } else {
            let gotoPage = this.gotoPage;
            if (current === 0) {
                pageBar = 
                <ul class="pagination-list">
                    <li><a class="pagination-link is-current" aria-label="Goto page 1" aria-current="page">1</a></li>
                    <li><a onClick={() => gotoPage(1)} class="pagination-link" aria-label="Goto page 2">2</a></li>
                    <li><a onClick={() => gotoPage(2)} class="pagination-link" aria-label="Goto page 3">3</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-1)} class="pagination-link" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            } else if (current == 1) {
                pageBar = 
                <ul class="pagination-list">
                    <li><a onClick={() => gotoPage(0)} class="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><a class="pagination-link is-current" aria-label="Goto page 2" aria-current="page">2</a></li>
                    <li><a onClick={() => gotoPage(2)} class="pagination-link" aria-label="Goto page 3">3</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-1)} class="pagination-link" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            } else if (current == 2) {
                pageBar = 
                <ul class="pagination-list">
                    <li><a onClick={() => gotoPage(0)} class="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><a onClick={() => gotoPage(1)} class="pagination-link" aria-label="Goto page 2">2</a></li>
                    <li><a class="pagination-link is-current" aria-label="Goto page 3" aria-current="page">3</a></li>
                    <li><a onClick={() => gotoPage(3)} class="pagination-link" aria-label="Goto page 4">4</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-1)} class="pagination-link" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            } else if (current == maxPages - 1) {
                pageBar = 
                <ul class="pagination-list">
                    <li><a onClick={() => gotoPage(0)} class="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-3)} class="pagination-link" aria-label="Goto page 2">{maxPages-2}</a></li>
                    <li><a onClick={() => gotoPage(maxPages-2)} class="pagination-link" aria-label="Goto page 3">{maxPages-1}</a></li>
                    <li><a class="pagination-link is-current" aria-current="page" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            } else if (current == maxPages - 2) {
                pageBar = 
                <ul class="pagination-list">
                    <li><a onClick={() => gotoPage(0)} class="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-3)} class="pagination-link" aria-label="Goto page 2">{maxPages-2}</a></li>
                    <li><a class="pagination-link is-current" aria-label="Goto page 3"aria-current="page">{maxPages-1}</a></li>
                    <li><a onClick={() => gotoPage(maxPages-1)} class="pagination-link" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            } else if (current == maxPages - 3) {
                pageBar = 
                <ul class="pagination-list">
                    <li><a onClick={() => gotoPage(0)} class="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-4)} class="pagination-link" aria-label="Goto page 3">{maxPages-3}</a></li>
                    <li><a class="pagination-link is-current" aria-label="Goto page 2" aria-current="page">{maxPages-2}</a></li>
                    <li><a onClick={() => gotoPage(maxPages-2)} class="pagination-link" aria-label="Goto page 3">{maxPages-1}</a></li>
                    <li><a onClick={() => gotoPage(maxPages-1)} class="pagination-link" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            } else {
                pageBar = 
                <ul class="pagination-list">
                    <li><a onClick={() => gotoPage(0)} class="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(current-1)} class="pagination-link" aria-label={"Go to page "+current}>{current}</a></li>
                    <li><a class="pagination-link is-current" aria-label={"Page "+(current+1)} aria-current="page">{current+1}</a></li>
                    <li><a onClick={() => gotoPage(current+1)} class="pagination-link" aria-label={"Goto page "+(current+2)}>{current+2}</a></li>
                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                    <li><a onClick={() => gotoPage(maxPages-1)} class="pagination-link" aria-label={"Goto page " + maxPages}>{maxPages}</a></li>
                </ul>;
            }
            
        }
        return (
            <nav class="pagination is-centered mb-3" role="navigation" aria-label="pagination">
                <a disabled={prevDisabled} class="pagination-previous" onClick={this.previousPage}>Previous</a>
                <a disabled={nextDisabled} class="pagination-next"  onClick={this.nextPage}>Next</a>
                {pageBar}
            </nav>
        )
    }
}

/*
<ul class="pagination-list">
    <li><a class="pagination-link" aria-label="Goto page 1">1</a></li>
    <li><span class="pagination-ellipsis">&hellip;</span></li>
    <li><a class="pagination-link" aria-label="Goto page 45">45</a></li>
    <li><a class="pagination-link is-current" aria-label="Page 46" aria-current="page">46</a></li>
    <li><a class="pagination-link" aria-label="Goto page 47">47</a></li>
    <li><span class="pagination-ellipsis">&hellip;</span></li>
    <li><a class="pagination-link" aria-label="Goto page 86">86</a></li>
</ul>
*/

export default SearchPagination;