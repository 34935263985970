import React from "react";

import RegisterForm from '../Accounts/RegisterForm.js';

class Register extends React.Component {
    componentDidMount() {
        document.title = "Register";
    }

    render() {
        return (
            <div class="section">
                <div class="container has-text-centered">
                    <h1 class="title">Register</h1>
                    <p>Register for an account to save items, create lists, and receive notifications.</p>
                </div>
                <div class="section columns is-centered">
                    <div class="column is-two-fifths">
                        <RegisterForm loggedIn={this.props.loggedIn} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;
