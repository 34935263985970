import React from "react";

class ItemCard extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="column is-one-fifth">
                <div className="card">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img alt="" src={this.props.item.image}/>
                        </figure>
                    </div>
                    <div className="card-content">
                        <div className="content">
                            <p className=""><span className="has-text-weight-bold">{this.props.item.name}</span><br/>Sold by: {this.props.item.marketplace}</p>
                            <p>${this.props.item.price}</p>
                        </div>
                    </div>
                    <footer className="card-footer">
                        <a href={"/item/"+this.props.item.uuid} rel="noreferrer" target="_blank" class="card-footer-item">More Information</a>
                    </footer>
                </div>
            </div>
        )
    }
}

export default ItemCard;