import React from "react";

class MainSearchForm extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    handleChange(e) {
        this.props.onSearchChange(e.target.value);
    }

    handleTypeChange(e) {
        this.props.onSearchTypeChange(e.target.value);
    }

    /*
    <label data-tooltip="Searches both online sources and database products." class="radio has-tooltip-bottom" disabled>
                            <input checked={this.props.searchType==="both"} onChange={this.handleTypeChange} value="both" type="radio" name="searchFrom" disabled/>
                            &nbsp;Both&nbsp;
                        </label>
    */
    render() {
        return (
            <form onSubmit={this.props.onSearchSubmit}>
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input className="input" type="search" value={this.props.query} onChange={this.handleChange} placeholder="Search for a product..." />
                    </div>
                    <div class="control">
                        <button class="button input" type="submit" value="Submit"><i class="fas fa-search"></i></button>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="label">
                        <label class="label">Search from:&nbsp;</label>
                    </div>
                    <div class="control">
                        <label data-tooltip="Searches online stores for items. Slower, but more complete. Results might be cached." class="radio has-tooltip-bottom">
                            <input checked={this.props.searchType==="online"} onChange={this.handleTypeChange} value="online" type="radio" name="searchFrom"/>
                            &nbsp;Online sources&nbsp;
                        </label>
                        <label data-tooltip="Searches our database. Faster results, but result completeness depends on products in the database." class="radio has-tooltip-bottom">
                            <input checked={this.props.searchType==="db"} onChange={this.handleTypeChange} value="db" type="radio" name="searchFrom"/>
                            &nbsp;Database&nbsp;
                        </label>
                        
                    </div>
                </div>
            </form>
        )
    }
}

export default MainSearchForm;