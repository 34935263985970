import React from "react";
import {
    Link
} from "react-router-dom";

class Nav extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        var accountBar;
        if (this.props.loggedIn !== "true") {
            accountBar = 
            <div class="navbar-end">
                <div class="navbar-item">
                    <Link to="/register" className="button button is-info">Sign Up</Link>
                </div>
                <div class="navbar-item">
                    <Link to="/login" className="button button">Login</Link>
                </div>
            </div>
        } else {
            accountBar = 
            <div class="navbar-end">
                <div class="navbar-item">
                    <Link to="/account" className="button button">Account</Link>
                </div>
            </div>
        }
        return (
            <nav class="navbar is-spaced has-shadow" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <a href="/" class="navbar-item"><img alt="Holoview" src="/static/images/logo.png"/></a>
                </div>
                <div class="navbar-menu">
                    <div class="navbar-start">
                        <div class="navbar-item">
                            <Link to="/" className="navbar-item">Home</Link>
                        </div>
                        <div class="navbar-item">
                            <Link to="/about" className="navbar-item">About</Link>
                        </div>
                        <div class="navbar-item">
                            <Link to="/help" className="navbar-item">Help</Link>
                        </div>
                    </div>
                    {accountBar}
                </div>
            </nav>
        )
    }
}

export default Nav;
