import React from "react";

import {
    Link
} from "react-router-dom";

import LoginForm from '../Accounts/LoginForm.js';

class Login extends React.Component {
    componentDidMount() {
        document.title = "Login";
    }

    render() {
        return (
            <div class="section">
                <div class="container has-text-centered">
                    <h1 class="title">Login</h1>
                    <p>Login to your account. <Link to="/register">Register</Link> if you do not have one.</p>
                </div>
                <div class="section columns is-centered">
                    <div class="column is-two-fifths">
                        <LoginForm loggedIn={this.props.loggedIn} setLogin={this.props.setLogin}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
