import React from "react";

class EditListName extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: false,
            message: "",
            nameVal: "",
            listid: this.props.listid,
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.editName = this.editName.bind(this);
    }

    handleNameChange(e) {
        this.setState({nameVal: e.target.value});
    }

    editName(event) {
        event.preventDefault();
        this.setState({loading: true});
        fetch("/api/authenticated/productlist/editname?name="+this.state.nameVal+"&listid="+this.state.listid)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false,
                        });
                        this.props.editListName(this.state.nameVal);
                        this.setState({nameVal:""});
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productsaves: [],
                        loading: false,
                        error: true
                    });
                }
            )
    }

    render() {
        return (
            <div className="container box">
                <form onSubmit={this.editName}>
                    <div class="field">
                        <div class="control">
                            <label class="label">New Name</label>
                            <input class="input" name="listname" value={this.state.nameVal} onChange={this.handleNameChange} type="text"/>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <button class="button is-link">Edit</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default EditListName;