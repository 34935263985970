import React from "react";
import AddProductList from "./AddProductList.js";
import ProductList from "./ProductList.js"

class ProductListsDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            productlists: [],
            loading: true,
            error: false,
            message: "",
            createList: false,
        };

        this.getLists = this.getLists.bind(this);
        this.toggleCreateList = this.toggleCreateList.bind(this);
    }

    toggleCreateList() {
        console.log(this.state.createList);
        this.setState({createList: !this.state.createList});
    }

    getLists() {
        this.setState({loading: true});
        fetch("/api/authenticated/productlists")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false,
                            productlists: result.lists
                        });
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productlists: [],
                        loading: false,
                        error: true
                    });
                }
            )
    }

    componentDidMount() {
        document.title = "Account - Product Lists";
        this.getLists();
    }

    render() {
        var toolbox;
        if (this.state.createList === true) {
            toolbox = <div className="">
                <AddProductList getLists={this.getLists}/>
            </div>;
        }
        var returnResults;
        if (this.state.loading) {
            returnResults = <div className="has-text-centered"><span class="is-size-2"><i className="fas fa-spinner fa-spin"></i></span><p></p></div>;
        } else if (this.state.loading === false && this.state.error) {
            returnResults = <div className="has-text-centered content"><p>{this.state.message}</p></div>;
        } else {
            if (this.state.productlists.length == 0) {
                returnResults = <p>No product lists found...</p>;
            } else {
                var comps = this.state.productlists.map((list) =>
                    <div className="container content">
                         <ProductList listName={list.name} getLists={this.getLists} listid={list.uuid} mod={list.modify}/>
                    </div>
                   
                );
                returnResults = comps;
            }
            
        }
        return (
            <div className="container">
                <div className="content">
                    <p>Create product lists to group items or save items for later. Note that all accounts have a default list which cannot be deleted. However, the name of that list can be changed.</p>
                </div>
                <div className="buttons content">
                    <button data-tooltip="Add New List" class="button" onClick={this.toggleCreateList}><i class="fas fa-plus"></i></button>
                </div>
                <div className="container content">
                    {toolbox}
                </div>
                {returnResults}
            </div>
        )
    }
}

export default ProductListsDisplay;