import React from "react";
import ProductSave from "./ProductSave.js"
import EditListName from "./EditListName.js"

class ProductList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            productsaves: [],
            loading: true,
            error: false,
            message: "",
            listid: this.props.listid,
            listName: this.props.listName,
            showModify: false,
        };
        this.toggleEditName = this.toggleEditName.bind(this);
        this.getSaves = this.getSaves.bind(this);
        this.deleteList = this.deleteList.bind(this);
        this.editListName = this.editListName.bind(this);
    }

    getSaves() {
        this.setState({loading: true});
        fetch("/api/authenticated/productsaves?listid="+this.state.listid)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false,
                            productsaves: result.products
                        });
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productsaves: [],
                        loading: false,
                        error: true
                    });
                }
            )
    }

    toggleEditName() {
        this.setState({showModify: !this.state.showModify});
    }

    editListName(val) {
        this.setState({listName:val,showModify:false});
    }

    deleteList() {
        fetch("/api/authenticated/productlist/delete?listid="+this.state.listid)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false
                        });
                        this.props.getLists();
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productsaves: [],
                        loading: false,
                        error: true
                    });
                }
            )
    }

    componentDidMount() {
        this.getSaves();
    }

    render() {
        var returnResults;
        if (this.state.loading) {
            returnResults = <div className=""><span class="is-size-1"><i className="fas fa-spinner fa-spin"></i></span><p></p></div>;
        } else if (this.state.loading === false && this.state.error) {
            returnResults = <div className="has-text-centered content"><p>{this.state.message}</p></div>;
        } else {
            if (this.state.productsaves.length == 0) {
                returnResults = <p>No products saved...</p>;
            } else {
                var comps = this.state.productsaves.map((save) =>
                    <ProductSave item={save} getSaves={this.getSaves}/>
                );
                returnResults = comps;
            }
            
        }

        var toolbox;
        if (this.state.showModify === true) {
            toolbox = <div className="">
                <EditListName listid={this.state.listid} editListName={this.editListName}/>
            </div>;
        }

        var delButton;
        if (this.props.mod === true) {
            delButton = <button class="button is-danger" onClick={this.deleteList}>Delete List</button>;
        } else {
            delButton = null;
        }
        return (
            <div className="container">
                <h4 className="title is-4">{this.state.listName}</h4>
                <div className="buttons">
                    {delButton}
                    <button data-tooltip="Edit List Name" class="button is-info" onClick={this.toggleEditName}><i class="fas fa-edit"></i></button>
                </div>
                <div className="container content">
                    {toolbox}
                </div>
                {returnResults}
            </div>
        )
    }
}

export default ProductList;