import React from "react";

class EditSaveComment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: false,
            message: "",
            comments: this.props.comments,
            saveid: this.props.saveid,
        };
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.editComments = this.editComments.bind(this);
    }

    handleCommentChange(e) {
        this.setState({comments: e.target.value});
    }

    editComments(event) {
        event.preventDefault();
        this.setState({loading: true});
        fetch("/api/authenticated/productsave/editcomment?comment="+this.state.comments+"&saveid="+this.state.saveid)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false,
                        });
                        this.props.editComment(this.state.comments);
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }

    render() {
        return (
            <div className="container box">
                <form onSubmit={this.editComments}>
                    <div class="field">
                        <div class="control">
                            <label class="label">Edit Comment</label>
                            <textarea class="textarea" name="comment" value={this.state.comments} onChange={this.handleCommentChange} type="text"/>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <button class="button is-link">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default EditSaveComment;