import React from "react";

import MainSearch from '../MainSearch/MainSearch.js';

class Home extends React.Component {
    componentDidMount() {
        document.title = "Home";
    }
    
    render() {
        return (
            <div class="section">
                <div class="columns">
                    <div class="container has-text-centered content column is-three-fifths">
                        <h1 class="title">Welcome to HoloView</h1>
                        <p>Enter a product name below to start you search. Find out more on how to use our system <a href="/help">here</a> or learn more about us <a href="/about">here</a>. <a href="/register">Sign up</a> for an account or <a href="/login">login</a> to take advantage of more features.</p>
                    </div>
                </div>
                <MainSearch />
            </div>
        )
    }
}

export default Home;
