import React from "react";

class About extends React.Component {
    componentDidMount() {
        document.title = "About";
    }

    render() {
        return (
            <div class="section">
                <div class="container content has-text-centered">
                    <h1 class="title">About</h1>
                </div>
                <div class="content">
                    <div class="container">
                        <p>HoloView is a tool designed to make shopping online easier. We collect search results from various online stores together to help you compare products as you shop. We provide various details on products in one place, and tools for you to save products and create lists for easier shopping. With so many options in the online marketplace, we hope to make shopping online just a little more manageable. Find out how to use our system <a href="/help">here</a>.</p>
                        <p>Currently, we provide data from 2 online stores:</p>
                        <div className="columns is-vcentered is-multiline">
                            <div className="column is-1">
                                <img alt="Walmart" src="/static/images/walmart.png"/>
                            </div>
                            <div className="column is-1">
                                <img alt="eBay" src="/static/images/ebay.png"/>
                            </div>
                        </div>
                        <p>HoloView is still in development. We are constantly adding more features and will continue to add more online stores. In the future, will also plan to implement technology to analyze products and help you make a more informed decision while you shop.</p>
                        <p>HoloView is currently being developed by Steven Su, a junior at Montgomery Blair High School.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;
