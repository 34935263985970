import React from "react";
import Reaptcha from "reaptcha";
import {
    Redirect
} from "react-router-dom";

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
            username: "",
            password: "",
            results: {},
            error: null,
            recaptcha: "",
            captchaReady: false,
            loading: false
        }
        this.captcha = null;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.reCaptchaUpdate = this.reCaptchaUpdate.bind(this);
        this.onVerify = this.onVerify.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        let nam = event.target.name;
        let val = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({[nam]:val});
    }

    onVerify(recaptchaResponse) {
        this.setState({'recaptcha':recaptchaResponse}, this.reCaptchaUpdate)
    }

    handleSubmit(event) {
        this.setState({loading: true});
        this.forceUpdate();
        event.preventDefault();
        this.captcha.execute();
    }

    reCaptchaUpdate() {
        fetch("/api/accounts/login", {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => res.json())
        .then(
                (result) => {
                    this.setState({results: result, error: false, loading: false});
                    console.log(result);
                    if (result.success === true) {
                        localStorage.setItem("uuid",result["uuid"]);
                        localStorage.setItem("username",result["username"]);
                        localStorage.setItem("name",result["name"]);
                        localStorage.setItem("email",result["email"]);
                        this.props.setLogin("true");
                    }  
                },
                (error) => {
                    this.setState({results: {}, error: true, loading: false});
                    console.log(error);
                }
        )
        this.captcha.reset();
    }

    render() {
        const recaptchaElement = <Reaptcha
            ref={e => (this.captcha = e)}
            sitekey="6LeFCYoaAAAAAJOOeL69qA_8ROBL79tTa3OKznSu"
            onVerify={this.onVerify}
            size="invisible"
        />;
        var message, mainbody;
        if (this.state.error === true) {
            message = <div className="content notification is-danger"><p>Sorry, we were unable to complete your request. Something is broken...</p></div>;
        } else {
            if (this.state.results.hasOwnProperty('success')) {
                if (this.state.results["success"]) {
                    message = <div className="content notification is-success"><p>Success! You will be redirected soon...</p></div>;
                } else {
                    const listItems = this.state.results["message"].map((item) => 
                        <li>{item}</li>
                    );
                    message = <div className="content notification is-danger"><p>Please correct the following errors:</p><ul>{listItems}</ul></div>;
                }
            } else {
                message = null;
            }
        }
        mainbody =
        <form onSubmit={this.handleSubmit}>
            {message}
            <div class="field">
                <div class="control">
                    <label class="label">Username</label>
                    <input class="input" name="username" value={this.state.username} onChange={this.handleChange} type="text"/>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <label class="label">Password</label>
                    <input class="input" name="password" value={this.state.password} onChange={this.handleChange} type="password"/>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <button class="button is-link" disabled={this.state.recaptchaReady}>Login</button>
                </div>
            </div>
        </form>;
        if (this.props.loggedIn === "true") {
            return <Redirect to="/account"/>;
        }
        if (this.state.loading === true) {
            mainbody = <div className="has-text-centered is-size-2"><i className="fas fa-spinner fa-spin"></i></div>
        }
        return (
            <div>
                {mainbody}
                {recaptchaElement}
            </div>
        )
    }
}

export default LoginForm;