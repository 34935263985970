import React from "react";
import EditSaveComment from "./EditSaveComment";

class ProductSave extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            productsave: this.props.item,
            loading: true,
            error: false,
            message: "",
            showCommentEdit: false,
            toggleEdit: false,
        };
        this.deleteSave = this.deleteSave.bind(this);
        this.editComment = this.editComment.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    toggleEdit() {
        this.setState({toggleEdit: !this.state.toggleEdit});
    }

    editComment(val) {
        var copy = this.state.productsave;
        copy.comments = val;
        this.setState({productsave:copy,toggleEdit:false});
    }

    deleteSave() {
        fetch("/api/authenticated/productsave/delete?saveid="+this.state.productsave.uuid)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false
                        });
                        this.props.getSaves();
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productsaves: [],
                        loading: false,
                        error: true
                    });
                }
            )
    }

    render() {
        var tempDate = new Date(this.state.productsave.created);
        var created = tempDate.toDateString() + " " + tempDate.getHours() + ":" + tempDate.getMinutes();
        var comments = "None";
        
        var toolbox;
        if (this.state.toggleEdit === true) {
            toolbox = <div className="">
                <EditSaveComment saveid={this.state.productsave.uuid} editComment={this.editComment} comments={this.state.productsave.comments}/>
            </div>;
        }

        if (this.state.productsave.comments !== "") {
            comments = this.state.productsave.comments;
        }
        return (
            <div className="container box">
                <div className="content">
                    <p><span className="has-text-weight-bold">{this.state.productsave.name}</span><br/><span>Saved on {created}</span></p>
                </div>
                <div className="content">Comments: {comments}</div>
                <div className="buttons">
                    <a data-tooltip="View Product" class="button is-info" href={"/item/"+this.state.productsave.item_uuid} target="_blank"><i class="fas fa-external-link-alt"></i></a>
                    <button data-tooltip="Edit Comments" class="button is-info" onClick={this.toggleEdit}><i class="fas fa-comment-alt"></i></button>
                    <button data-tooltip="Remove Save" class="button is-danger" onClick={this.deleteSave}><i class="fas fa-trash-alt"></i></button>
                </div>
                {toolbox}
            </div>
        )
    }
}

export default ProductSave;