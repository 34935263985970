import React from "react";

class SaveProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleTool: false,
            loading: true,
            error: false,
            message: "",
            targetid: "",
            productlists: [],
            currentlists: [],
        };

        this.handleTargetChange = this.handleTargetChange.bind(this);
        this.addSave = this.addSave.bind(this);
        this.toggleToolBox = this.toggleToolBox.bind(this);
        this.getLists = this.getLists.bind(this);
        this.getExistingLists = this.getExistingLists.bind(this);
    }

    toggleToolBox() {
        this.setState({toggleTool: !this.state.toggleTool});
    }

    handleNameChange(e) {
        this.setState({nameVal: e.target.value});
    }

    getLists() {
        fetch("/api/authenticated/productlists")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({
                            error: false,
                            productlists: result.lists,
                            targetid: "",
                        });
                    } else {
                        this.setState({
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productlists: [],
                        error: true
                    });
                }
            )
    }

    getExistingLists() {
        fetch("/api/authenticated/item/getsaves?itemid=" + this.props.itemid)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    if (result.success) {
                        this.setState({
                            error: false,
                            currentlists: result.products,
                            targetid: "",
                        });
                    } else {
                        this.setState({
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        currentlists: [],
                        error: true
                    });
                }
            )
    }

    getInfo() {
        this.setState({loading: true});
        this.getLists();
        this.getExistingLists();
        this.setState({loading: false, targetid:""});
    }

    handleTargetChange(event) {
        const target = event.target;
        let val = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({targetid:val});
    }

    addSave(event) {
        event.preventDefault();
        this.setState({loading: true});
        fetch("/api/authenticated/productsave/create?listid="+this.state.targetid + "&itemid=" + this.props.itemid)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        this.setState({
                            error: false,
                            targetid: "",
                        });
                        this.getInfo();
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        productsaves: [],
                        loading: false,
                        error: true
                    });
                }
            )
    }

    componentDidMount() {
        this.getInfo();
    }

    render() {
        var toolsection;
        if (this.state.toggleTool) {
            if (this.state.loading) {
                toolsection = <div className="has-text-centered"><span class="is-size-2"><i className="fas fa-spinner fa-spin"></i></span><p></p></div>;
            } else {
                // show existing lists
                var existingProducts;
                if (this.state.currentlists != null && this.state.currentlists.length > 0) {
                    var listNames = this.state.currentlists.map(l => <li>{l.list_name}</li>);
                    existingProducts = <div className="content">
                        <p>
                            You have already saved this product in the following lists:
                            <ul>
                                {listNames}
                            </ul>
                        </p>
                    </div>
                } else {
                    existingProducts = <div className="content">
                        <p>
                            You have not saved this product yet.
                        </p>
                    </div>
                }

                // generate options for new lists
                var saveForm;
                if (this.state.productlists != null) {
                    var current;
                    if (this.state.currentlists != null) {
                        current = this.state.currentlists.map(l => l.list_uuid);
                    } else {
                        current = Array();
                    }

                    var options = Array();
                    var first = "";
                    var i = 0;
                    var hasOne = false;
                    this.state.productlists.forEach(l => {
                        if (!current.includes(l.uuid)) {
                            if (i === 0) {
                                first = l.uuid;
                                hasOne = true;
                            }
                            options.push(<option value={l.uuid}>{l.name}</option>);
                            i++;
                        }
                    });
                    
                    if (this.state.targetid === "" && hasOne) {
                        console.log("updating target")
                        this.setState({targetid:first});
                    }
                    
                    if (options.length == 0) {
                        saveForm = <div className="content">
                            <p>
                                There are no more lists to add this product too!
                            </p>
                        </div>;
                    } else {
                        console.log(this.state.targetid);
                        saveForm = <form onSubmit={this.addSave}>
                            <div class="field">
                            <label class="label">Save to:</label>
                                <div class="control">
                                    <div class="select">
                                        <select name="targetlist" onChange={this.handleTargetChange}>
                                            {options}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <button class="button is-link">Save</button>
                                </div>
                            </div>
                        </form>;
                    }
                }
                toolsection = <div className="container box">
                    <div className="content">
                        {existingProducts}
                    </div>
                    <div class="content">
                        {saveForm}
                    </div>
                    <div className="buttons content">
                        <a className="button is-info" href="/account/products">Manage Saved Products</a>
                    </div>
                    
                </div>
            }
        }
        return (
            <div class="container">
                <div class="buttons">
                    <button class="button is-info" onClick={this.toggleToolBox}>Save Product</button>
                </div>
                {toolsection}
            </div>
        )
    }
}

export default SaveProduct;