import React from "react";
import ImageView from "./ImageView"

import {
    Link
} from "react-router-dom";
import SaveProduct from "../ProductLists/SaveProduct";

class ItemPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            error: false,
            message: "",
            item: {},
        }
        this.GetInfo = this.GetInfo.bind(this);
    }

    GetInfo() {
        this.setState({loading: true});
        fetch("/api/item?" + "uuid=" + this.props.match.params.uuid)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        this.setState({
                            loading: false,
                            error: false,
                            item: result.item
                        });
                    } else {
                        this.setState({
                            loading: false,
                            error: true,
                            message: result.error
                        });
                    }
                    
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }
    
    componentDidMount() {
        document.title = "Item View";
        this.GetInfo();
    }

    render() {
        var returnResults;
        if (this.state.loading) {
            returnResults = <div className="has-text-centered"><span class="is-size-2"><i className="fas fa-spinner fa-spin"></i></span><p></p></div>;
        } else if (this.state.loading === false && this.state.error) {
            returnResults = <div className="has-text-centered content"><p>{this.state.message}</p></div>;
        } else {
            var imageLinks = new Array(this.state.item.image);
            var item = this.state.item;
            console.log(this.state.item.name);
            console.log(item);
            if ("otherImages" in item["data"]) {
                imageLinks.push(...item["data"]["otherImages"]);
            }
            
            var extraData = {};
            if (item.reviews >= 0) {
                extraData["Number of Reviews"] = item.reviews;
            }
            if (item.rating >= 0 && item.reviews > 0) {
                extraData["Rating"] = item.rating;
            }
            if (item.condition !== "") {
                extraData["Condition"] = item.condition;
            }
            if ("brandName" in item["data"]) {
                extraData["Brand Name"] = item["data"]["brandName"];
            }
            if ("color" in item["data"]) {
                extraData["Color"] = item["data"]["color"];
            }
            var tempDate = new Date(item.last_updated);
            extraData["Information Last Updated"] = tempDate.toDateString() + " " + tempDate.getHours() + ":" + tempDate.getMinutes();

            var extraInfoRows = new Array();
            var extraKeys = Object.keys(extraData);
            for (var i = 0; i < extraKeys.length; i += 2) {
                var firstKey = extraKeys[i];
                console.log(firstKey);
                var firstVal = extraData[firstKey];
                console.log(extraData);
                if (i + 1 < extraKeys.length) {
                    var secondKey = extraKeys[i+1];
                    var secondVal = extraData[secondKey];
                    extraInfoRows.push(<tr key={i}><th>{firstKey}</th><td>{firstVal}</td><th>{secondKey}</th><td>{secondVal}</td></tr>);
                } else {
                    extraInfoRows.push(<tr key={i}><th>{firstKey}</th><td>{firstVal}</td><th></th><td></td></tr>);
                }
            }
            if (extraInfoRows.length === 0) {
                extraInfoRows = <tr><td>No additional information available!</td></tr>;
            }
            console.log(extraInfoRows);

            returnResults = 
            <div className="has-text-centered container">
                <div class="container">
                    <div className="columns">
                        <div className="column is-6">
                            <ImageView links={imageLinks}></ImageView>
                        </div>
                        <div className="column is-6 has-text-left content">
                            <h1 className="title">{this.state.item.name}</h1>
                            <SaveProduct itemid={this.state.item.uuid}/>
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <th>Price</th>
                                        <td>{"$"+this.state.item.price}</td>
                                    </tr>
                                    <tr>
                                        <th>Marketplace</th>
                                        <td>{this.state.item.marketplace}</td>
                                    </tr>
                                    <tr>
                                        <th>Seller</th>
                                        <td>{this.state.item.seller.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Purchase/Original Page</th>
                                        <td><a href={this.state.item.url} className="is-info button" target="_blank">View</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="container content">
                    <h3 className="b title">Additional Information</h3>
                    <table class="table has-text-left">
                        <tbody>
                            {extraInfoRows}
                        </tbody>
                    </table>
                </div>
                
            </div>;
        }
        /*
        <div className="container content">
                    <h3 className="b title">Product Groups</h3>
                    <p className="block">View product groups to compare prices and overall reviews for a single product. Product Groups collect items from different online stores and sellers together into one location to provide an overview of options available.</p>
                    <p>TODO - Sprint 6</p>
                </div>
        */
        return (
            <div class="section">
                {returnResults}
            </div>
        )
    }
}

export default ItemPage;
