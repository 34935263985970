import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Nav from "./Nav.js";
import Home from "./Home.js";
import Register from "./Register.js";
import NotFound from "./NotFound.js";
import Login from "./Login.js";
import Account from "./Account.js";
import ItemPage from "./ItemPage.js";
import About from "./About.js";
import Help from "./Help.js";

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loggedIn: ((localStorage.getItem("loggedIn") == "false") || (localStorage.getItem("loggedIn") == null) ? "false" : "true"),
        }
        this.setLogin = this.setLogin.bind(this);
    }

    setLogin(val) {
        this.setState({"loggedIn":val});
        localStorage.setItem("loggedIn",val);
    }

    render() {
        return (
            <Router>
                <Nav loggedIn={this.state.loggedIn} />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/register">
                        <Register loggedIn={this.state.loggedIn} />
                    </Route>
                    <Route exact path="/login">
                        <Login loggedIn={this.state.loggedIn} setLogin={this.setLogin} />
                    </Route>
                    <Route path="/account">
                        <Account loggedIn={this.state.loggedIn} setLogin={this.setLogin} />
                    </Route>
                    <Route exact path="/item/:uuid" component={ItemPage} />
                    <Route exact path="/about">
                        <About/>
                    </Route>
                    <Route exact path="/help">
                        <Help/>
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default App;
