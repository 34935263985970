import React from "react";
import {
    Redirect,
    NavLink,
    Route,
    Switch
} from "react-router-dom";
import ChangePassword from "../Accounts/ChangePassword.js";
import ModifyAccountForm from "../Accounts/ModifyAccountForm.js";
import ProductListsDisplay from "../ProductLists/ProductListsDisplay.js"

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        document.title = "Account";
    }

    logout() {
        fetch("/api/accounts/logout", {
            method: 'GET'
        }).then(response => {
            this.props.setLogin("false");
        })
    }

    render() {
        if (this.props.loggedIn == "false") {
            return (
                <Redirect to="/login"/>
            )
        }
        // <li><NavLink exact={true} activeClassName='is-active' to="/account/changepassword">Change Password</NavLink></li>
        // <li><NavLink exact={true} activeClassName='is-active' to="/account/watched">Watched Products</NavLink></li>
        return (
            <div className="section container is-widescreen">
                <div className="content has-text-centered">
                    <h1 class="title">Account</h1>
                </div>
                <div className="columns">
                    <div className="column is-one-third">
                        <div class="menu">
                            <p class="menu-label">Account</p>
                            <ul class="menu-list">
                                <li><NavLink exact={true} activeClassName='is-active' to="/account">Profile</NavLink></li>
                                <li><NavLink exact={true} activeClassName='is-active' to="/account/changepassword">Change Password</NavLink></li>
                                <li><NavLink exact={true} activeClassName='is-active' to="/account/logout">Logout</NavLink></li>
                            </ul>
                            <p class="menu-label">Products</p>
                            <ul class="menu-list">
                                <li><NavLink exact={true} activeClassName='is-active' to="/account/products">Product Lists</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div class="column is-two-thirds">
                        <Switch>
                            <Route exact path="/account/logout">
                                <div class="content">
                                    <p>Are you sure you want to logout?</p>
                                    <button className="button is-danger" onClick={this.logout}>Logout</button>
                                </div>
                            </Route>
                            <Route exact path="/account">
                                <ModifyAccountForm />
                            </Route>
                            <Route exact path="/account/changepassword">
                                <ChangePassword />
                            </Route>
                            <Route exact path="/account/products">
                                <ProductListsDisplay/>
                            </Route>
                            <Route path="/account/*">
                                <div class="content">
                                    <p>To be implemented</p>
                                </div>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
            
            
        )
    }
}

export default Account;
