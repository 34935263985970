import React from "react";

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props)
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            numItems: this.props.links.length,
            current: 0,
            links: this.props.links,
        }
    }

    previousPage() {
        if (this.state.current === 0) {
            return;
        }
        this.setState({current:this.state.current-1});
    }

    nextPage() {
        if (this.state.current === this.state.numItems-1) {
            return;
        }
        this.setState({current:this.state.current+1});
    }

    render() {
        var prevDisabled = this.state.current === 0;
        var nextDisabled = this.state.current === (this.state.numItems-1);
        var current = this.state.current;
        return (
            <div>
                <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                    <a disabled={prevDisabled} class="pagination-previous" onClick={this.previousPage}>Previous</a>
                    <a disabled={nextDisabled} class="pagination-next"  onClick={this.nextPage}>Next</a>
                    <ul class="pagination-list">
                        <li><span>Image {current+1} of {this.state.numItems}</span></li>
                    </ul>
                </nav>
                <div class="container">
                    <img src={this.state.links[current]} alt=""/>
                </div>
            </div>
        )
    }
}

export default ImageCarousel;