import React from "react";

class NotFound extends React.Component {
    componentDidMount() {
        document.title = "404 Not Found";
    }

    render() {
        return (
            <div class="section">
                <div class="container has-text-centered">
                    <h1 class="title">404 Page Not Found</h1>
                    <p>Sorry, the URL you reuqested was not found. Please contact us if you think this in an error.</p>
                </div>
            </div>
        )
    }
}

export default NotFound;
