import React from "react";
import MainSearchForm from "./MainSearchForm";
import SearchResults from "./SearchResults";

class MainSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            items: [],
            error: null,
            loading: false,
            searched: false,
            resultsPerPage: 15,
            pageNum: 0,
            sortMethod: "relevance",
            searchType: "online"
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchTypeChange = this.handleSearchTypeChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.changePageNum = this.changePageNum.bind(this);
        this.getPageNum = this.getPageNum.bind(this);
        this.changeIpp = this.changeIpp.bind(this);
        this.changeSort = this.changeSort.bind(this);
    }

    changeSort(val) {
        this.setState({sortMethod: val});
    }

    changeIpp(val) {
        this.setState({resultsPerPage: val});
    }

    changePageNum(val) {
        this.setState({pageNum: val});
    }

    getPageNum() {
        return this.state.pageNum;
    }

    handleSearchChange(searchText) {
        this.setState({searchValue: searchText});
    }

    handleSearchTypeChange(val) {
        this.setState({searchType: val});
    }

    handleSearchSubmit(event) {
        this.setState({loading: true, searched: true});
        fetch("/api/search?" + "search=" + this.state.searchType + "&q=" + this.state.searchValue)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    this.setState({
                        items: result.results,
                        loading: false,
                        error: false
                    });
                },
                (error) => {
                    this.setState({
                        items: [],
                        loading: false,
                        error: true
                    });
                }
            )
        event.preventDefault();
    }

    render() {
        var returnResults;
        if (this.state.loading) {
            returnResults = <div className="has-text-centered"><span class="is-size-2"><i className="fas fa-spinner fa-spin"></i></span><p><i>This make take a while...</i></p></div>;
        } else {
            if (this.state.error === true) {
                returnResults = <p>An error occurred in this search</p>
            } else {
                if (this.state.searched && this.state.items.length === 0) {
                    returnResults = <div className="has-text-centered"><p className="has-text-weight-bold">No results found. Try other search terms or change the "Search from" option to "online".</p></div>
                } else if (this.state.searched && this.state.items.length !== 0) {
                    returnResults = <SearchResults 
                                    items={this.state.items}
                                    resultsPerPage={this.state.resultsPerPage}
                                    pageNum={this.state.pageNum}
                                    changeNum={this.changePageNum}
                                    getNum={this.getPageNum}
                                    changeIpp={this.changeIpp}
                                    changeSort={this.changeSort}
                                    sortMethod={this.state.sortMethod}
                                />
                }
            }
        }
        return (
            <div className="section">
                <div className="columns is-centered">
                    <div className="is-three-fifths column">
                        <MainSearchForm
                            query={this.state.searchValue}
                            onSearchChange={this.handleSearchChange}
                            onSearchTypeChange={this.handleSearchTypeChange}
                            onSearchSubmit={this.handleSearchSubmit}
                            searchType={this.state.searchType}
                        />
                    </div>
                </div>
                <div className="container has-text-centered">
                    <div className="">
                        <p className="bold">Supports stores:</p>
                        <div className="columns is-vcentered is-centered is-multiline">
                            <div className="column is-1">
                                <img alt="Walmart" src="/static/images/walmart.png"/>
                            </div>
                            <div className="column is-1">
                                <img alt="eBay" src="/static/images/ebay.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns is-centered">
                    <div className="column is-four-fifths">
                        {returnResults}
                    </div>
                </div>
            </div>
        )
    }
}

export default MainSearch;