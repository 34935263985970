import React from "react";
import ItemCard from "./ItemCard";
import SearchPagination from "./SearchPagination";

class SearchResults extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        let nam = event.target.name;
        let val = target.type === 'checkbox' ? target.checked : target.value;
        if (nam === "ipp") {
            this.props.changeNum(0);
            this.props.changeIpp(parseInt(val,10));
        } else if (nam === "sort-method") {
            this.props.changeSort(val);
        }
    }

    render() {
        const resultsPerPage = this.props.resultsPerPage;
        const sortMethod = this.props.sortMethod;
        var start = this.props.pageNum * this.props.resultsPerPage;
        var end = Math.min(this.props.items.length, start + this.props.resultsPerPage);
        if (sortMethod === "relevance") {
            this.props.items.sort(function (a,b) {
                return a.order_id - b.order_id; 
            })
        } else if (sortMethod === "price-low") {
            this.props.items.sort(function (a,b) {
                return a.price - b.price; 
            })
        } else if (sortMethod === "price-high") {
            this.props.items.sort(function (a,b) {
                return -1*a.price + b.price; 
            })
        }
        
        var finalList = this.props.items.slice(start,end);
        var listItems = finalList.map((item) => 
            <ItemCard 
                item={item}
            />
        );
        
        return (
            <div>
                <div>
                    <div class="columns">
                        <div class="column is-one-fifth">
                            <label class="label">Sort by:</label>
                            <div class="select">
                                <select name="sort-method" onChange={this.handleChange} value={sortMethod}>
                                    <option value="relevance">Relevance</option>
                                    <option value="price-high">Highest Price</option>
                                    <option value="price-low">Lowest Price</option>
                                </select>
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <label class="label">Items per page:</label>
                            <div class="select">                      
                                <select onChange={this.handleChange} name="ipp" value={resultsPerPage}>
                                    <option value="15">15</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <p>Showing {start+1} - {Math.min(end,this.props.items.length)} out of {this.props.items.length} results</p>
                    </div>
                    <SearchPagination 
                        total={this.props.items.length}
                        current={this.props.pageNum}
                        size={this.props.resultsPerPage}
                        changeNum={this.props.changeNum}
                        getNum={this.props.getNum}
                    />
                </div>
                <div className="columns is-multiline">
                    {listItems}
                </div>
            </div>
            
        )
    }
}

export default SearchResults;