import React from "react";

class Help extends React.Component {
    componentDidMount() {
        document.title = "Help";
    }

    render() {
        return (
            <div class="section">
                <div class="container content has-text-centered">
                    <h1 class="title">Help</h1>
                    <p>Find answers to commonly asked questions and instructions on how to use our tools.</p>
                </div>
                <div class="container content">
                    <h2 class="title">Instructions</h2>
                    <p><h3 class="subtitle">How do I perform a search?</h3>Enter a search term on the <a href="/">Home</a> page in the box. Hit the Enter key or click on the magnifying glass button (<i class="fas fa-search"></i>) to get the results. You can also choose the search source by clicking on "Online sources" or "Database". Learn the difference between these options <a href="#search-explanation">here</a>.</p>
                    <p><h3 class="subtitle">How do I sort and filter results?</h3>After a search is complete, you will see results listed below. You will also see a series of sorting and filtering mechanisms. Simply choose an option from the dropdown to make the sort/filter. "Sort by" allows you to sort products by relevance to your search term, or by price (ascending and descending). "Items per page" allows you to choose the maximum number of products listed at any given time.</p>
                    <p><h3 class="subtitle">How do I view more products?</h3>Click the "Previous" or "Next" buttons to move between pages of product listings. You can also use the buttons with numbers to go to a specific page. If you want to see more products in a page, change the "Items per page" dropdown. Currently, we are unable to return more <i>total</i> results (i.e. the c in "Showing a-b out of c results")</p>
                    <p><h3 class="subtitle">How do I get more information about a product or go to the original page?</h3>Under each product listing in the search results, you will see a button that says "More Information". Click on that button and you will see a page with more images, details, and a link to the original page.</p>
                    <p><h3 class="subtitle">How do I get an account?</h3>Head over to our <a href="/register">registration page</a> to create an account.</p>
                    <p><h3 class="subtitle">How do I get login?</h3>You may login at <a href="/login">this page</a> after you have registered for an account.</p>
                    <p><h3 class="subtitle">How do I logout?</h3>Head to the <a href="/account">Account</a> page and click on the "Logout" bar under the "Account" menu. A red "Logout" button should appear, click it.</p>
                    <p><h3 class="subtitle">How do I save products?</h3>Note: you need to have an account and be logged in to do this. First, go to the product details page for any product. This can be done by clicking the "More Information" button under each product listing. Click the blue "Save Product" button, select the product list you would like to save the product to with the "Save to" dropdown, then click the "Save" button. You can save a single product to multiple lists, but a single product can appear on a list only once. You need an account to save products.</p>
                    <p><h3 class="subtitle">How do I create product lists, delete product saves, or delete lists?</h3>Note: you need to have an account and be logged in to do this. Head to the <a href="/account">Account</a> page and click on <a href="/account/products">Product Lists</a>. Here, you create new lists, delete lists (red Delete List button), view saved products (<i class="fas fa-external-link-alt"></i> button), and delete saved products (<i class="fas fa-trash-alt"></i>) by clicking on their associated buttons. You can change list names by using the <i class="fas fa-edit"></i> button, and you can add comments to product saves by using the <i class="fas fa-comment-alt"></i> button.</p>
                    <p><h3 class="subtitle">How do edit my profile?</h3>Note: you need to have an account and be logged in to do this. You are able to edit your name, username, and email on <a href="/account">this page</a>. You can change your password on <a href="/account/changepassword">this page</a>. You will need to enter your original password in either case for security reasons.</p>
                </div>
                <div class="container content">
                    <h2 class="title">Frequently Asked Questions</h2>
                    <p id="search-explanation"><h3 class="subtitle">What is the difference between "Online sources" and "Database"?</h3>The "Online sources" option performs a new search on each of the online stores. This provides an up-to-date view on the products currently available. However, old products may still be cached meaning details like pricing may be a little old, depending on the cache time. An "Online sources" search will take more time because of the new search queries performed. The "Database" search looks directly in our database for products. These results are not necessarily up-to-date, but this search is much faster and can potentially return more products. We suggest that you first browse through a "Database" search and try and "Online sources" search if you need more products or want the most up-to-date listings.</p>
                    <p id="search-explanation"><h3 class="subtitle">How do you get product information?</h3>We use various methods to grab product data from online stores. One such method includes using available APIs provided by online stores to search for and collect product details. We have other methods of getting product information, but cannot disclose them for operation reasons.</p>
                    <p id="search-explanation"><h3 class="subtitle">What are product comments?</h3>Product comments allow you to leave your thoughts about a product for later..</p>
                    <p id="search-explanation"><h3 class="subtitle">Will you support X store or add Y feature?</h3>HoloView is constantly being developed. We plan to add more online stores as the product is developed and more features will be added over time.</p>
                </div>
                <div class="container content">
                    <p><b>Thank you for using HoloView. If you have any more questions or have any suggestions/requests, feel free to contact the developer at lakewood999 (at) gmail.com</b></p>
                </div>
            </div>
        )
    }
}

export default Help;
